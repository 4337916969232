/* Copyright G. Hemingway, @2023 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {useLocation, useNavigate} from "react-router-dom";
import {
    ErrorMessage,
    FormBase,
    FormLabel,
    FormInput,
    FormButton,
} from "./shared.js";

export const GitHubLogin = (props) => {
    let navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const username = query.get('username');

    useEffect(() => {
        if (username) {
            props.logIn(username);
            // Optionally save the token in the app state or local storage
            // Redirect to profile or another page
            navigate(`/profile/${username}`);
        }
    }, [username]);

    return (
        <div>Finalizing login...</div>
    );
}

