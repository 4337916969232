/* Copyright G. Hemingway, @2023 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Pile } from "./pile.js";
import {GameErrorMessage, FormButton, ModalNotify, ButtonsBase} from "./shared.js";
//import { DndContext } from '@dnd-kit/core';

const CardRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2em;
`;

const CardRowGap = styled.div`
  flex-grow: 2;
`;

const GameBase = styled.div`
  grid-row: 2;
  grid-column: main;
`;

export const Game = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  let [state, setState] = useState({
    pile1: [],
    pile2: [],
    pile3: [],
    pile4: [],
    pile5: [],
    pile6: [],
    pile7: [],
    stack1: [],
    stack2: [],
    stack3: [],
    stack4: [],
    draw: [],
    discard: [],
  });
  let [startDrag, setStartDrag] = useState({ x: 0, y: 0 });
  let [selected, setSelected] = useState([]);
  let [sourcePile, setSourcePile] = useState("");
  let [error, setError] = useState("");
  let [hideError, setHideError] = useState(true);
  // let [endGame, setEndGame] = useState(false);
  let [notify, setNotify] = useState(false);

  useEffect(() => {
    const getGameState = async () => {
      const response = await fetch(`/v1/game/${id}`);
      const data = await response.json();
      setState({
        pile1: data.pile1,
        pile2: data.pile2,
        pile3: data.pile3,
        pile4: data.pile4,
        pile5: data.pile5,
        pile6: data.pile6,
        pile7: data.pile7,
        stack1: data.stack1,
        stack2: data.stack2,
        stack3: data.stack3,
        stack4: data.stack4,
        draw: data.draw,
        discard: data.discard,
      });
    };
    getGameState();
  }, [id]);

  // Control the error message display
  useEffect(() => {
    if (error !== "") {
      setHideError(false);
      setTimeout(() => {
        setError("");
        setHideError(true);
      }, 1000);
    }
  }, [error]);

  // Add event listener for escape key
  useEffect(() => {
    const keyPressHandler = (ev) => {
      // console.log(ev.key);
      if (ev.key === "Escape") {
        console.log("Escape")
        setNotify(false);
        setError("");
        setHideError(true);
        setSelected([]);
        setSourcePile("");
      }
    }
    document.addEventListener("keydown", keyPressHandler);

    return () => {
        document.removeEventListener("keydown", keyPressHandler);
    };
  },[]);

  /*
  * Handle the move of cards
  * @param {cards} Array of cards to move
  * @param {src} Source pile
  * @param {dist} Destination pile
  * @return {200} Game information
  * @return {400} Error message
   */
  const handleMove = async (cards, src, dist) => {
    let res = await fetch(`/v1/game/${id}`, {
      method: "PUT",
      body: JSON.stringify({
        move: {
          src: src,
          dist: dist,
          cards: cards
        },
      }),
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
    });
    console.log({cards: cards, src: src, dist: dist});
    const data = await res.json();
    if (res.ok) {
      setState({
        pile1: data.pile1,
        pile2: data.pile2,
        pile3: data.pile3,
        pile4: data.pile4,
        pile5: data.pile5,
        pile6: data.pile6,
        pile7: data.pile7,
        stack1: data.stack1,
        stack2: data.stack2,
        stack3: data.stack3,
        stack4: data.stack4,
        draw: data.draw,
        discard: data.discard,
      });
      setNotify(data.endGame);
    } else {
      console.log(data.error);
      setError(data.error);
    }
  }

  /*
    * Get the cards from the pile starting at the target card
   */
  const getCards = (target, pile) => {
    const index = state[pile].findIndex(card => `${card.suit}:${card.value}` === target)
    return state[pile].slice(index)
  }

  /*
    * Handle the end of a drag
    * @param {ev} Drag event
   */
  const handleDragEnd = (ev) => {
    const {active, over, activatorEvent} = ev;
    activatorEvent.preventDefault();
    activatorEvent.stopPropagation();
    console.log("drag end")
    console.log(active)
    console.log(over)
    const src = active.id;
    const dist = over.id;

    if (dist === "board" ||
        dist === "discard"
    ) {
      console.log("reset")
      setSelected([]);
      setSourcePile("");
      return;
    }

    // If we are clicking on the draw pile, then we need to draw a card
    if (dist === "draw") {
      console.log("draw one card")
      let cards = getCards(ev.target.id, over);
      if (cards.length > 0) {
        handleMove(cards, over.id, "discard");
      } else {
        handleMove(state.discard, "discard", "draw")
      }
      setSelected([]);
      setSourcePile("");
      return;
    }
    // If we are clicking on the face down card, then we deselect everything
    if (activatorEvent.target.currentSrc.includes("face_down")) {
        console.log("face down")
        setSelected([]);
        setSourcePile("");
        return;
    }

    // If we are clicking on piles, then we need to move cards
    // console.log(selected)


    // if (active.id !== over.id) {
    //   // Perform the move based on the ID of the dragged item and the drop zone
    //   const cards = getCards(active.id, /* determine source pile from active.id */);
    //   const sourcePile = /* determine source pile from active.id */;
    //   const targetPile = /* determine target pile from over.id */;
    //   handleMove(cards, sourcePile, targetPile);
    // }
  };

  /*
    * Handle the click on a pile and the background
   */
  const onClick = (ev, pile) =>  {
    //console.log("click")
    ev.stopPropagation();
    ev.preventDefault();
    if (pile === "board") {
        console.log("board area clicked, reset")
        setSelected([]);
        setSourcePile("");
        return;
    }
    // If we are clicking on the draw pile, then we need to draw a card
    if (pile === "draw") {
        console.log("draw one card")
        let cards = getCards(ev.target.id, pile);
        if (cards.length > 0) {
          handleMove(cards, pile, "discard");
        } else {
          handleMove(state.discard, "discard", "draw")
        }
        setSelected([]);
        setSourcePile("");
        return;
    }
    // If we are clicking on the face down card, then we deselect everything
    if (ev.target.src && ev.target.src.includes("face_down")) {
        console.log("face down")
        setSelected([]);
        setSourcePile("");
        return;
    }
    // If we are clicking on piles, then we need to move cards
    // console.log(selected)
    if (selected.length > 0 && sourcePile !== pile) {
      console.log("move")
      handleMove(selected, sourcePile, pile);
      setSelected([]);
      setSourcePile("");
      return;
    }
    let target = ev.target.id;
    let cards = getCards(target, pile);
    setSelected(cards);
    setSourcePile(pile);
  };

  const onUndo = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    console.log("undo")
    let res = await fetch(`/v1/game/${id}/undo`);
    const data = await res.json();
    console.log(data)
    if (res.ok) {
      setState({
        pile1: data.pile1,
        pile2: data.pile2,
        pile3: data.pile3,
        pile4: data.pile4,
        pile5: data.pile5,
        pile6: data.pile6,
        pile7: data.pile7,
        stack1: data.stack1,
        stack2: data.stack2,
        stack3: data.stack3,
        stack4: data.stack4,
        draw: data.draw,
        discard: data.discard,
      });
    } else {
      console.log(data.error);
      setError(data.error);
    }
  }

  const onRedo = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    console.log("undo")
    let res = await fetch(`/v1/game/${id}/redo`);
    const data = await res.json();
    console.log(data)
    if (res.ok) {
      setState({
        pile1: data.pile1,
        pile2: data.pile2,
        pile3: data.pile3,
        pile4: data.pile4,
        pile5: data.pile5,
        pile6: data.pile6,
        pile7: data.pile7,
        stack1: data.stack1,
        stack2: data.stack2,
        stack3: data.stack3,
        stack4: data.stack4,
        draw: data.draw,
        discard: data.discard,
      });
    } else {
      console.log(data.error);
      setError(data.error);
    }
  }

  const onEndGameAccept = async (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const response = await fetch(`/v1/game/${id}/end`);
    const data = await response.json();
    setNotify(false);
    console.log("end game")
    navigate(`/results/${id}`);
  }

  const onNotifyDecline = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    setNotify(false);
  }

  return (
    // <DndContext onDragEnd={handleDragEnd}>
      <GameBase onClick={ev => onClick(ev, "board")} onDragOver={(e) => e.preventDefault()}>
        <ButtonsBase>
          {/*<FormButton >Auto Complete</FormButton>*/}
          <FormButton onClick={onUndo}>Undo</FormButton>
          <FormButton onClick={onRedo}>Redo</FormButton>
        </ButtonsBase>
        <CardRow>
          <Pile id={"stack1"} cards={state.stack1} spacing={0} onClick={ ev => onClick(ev, "stack1")} selected={selected}/>
          <Pile id={"stack2"} cards={state.stack2} spacing={0} onClick={ ev => onClick(ev, "stack2")} selected={selected}/>
          <Pile id={"stack3"} cards={state.stack3} spacing={0} onClick={ ev => onClick(ev, "stack3")} selected={selected}/>
          <Pile id={"stack4"} cards={state.stack4} spacing={0} onClick={ ev => onClick(ev, "stack4")} selected={selected}/>
          <CardRowGap />
          <Pile id={"draw"} cards={state.draw} spacing={0} onClick={ ev => onClick(ev, "draw")} selected={selected}/>
          <Pile id={"discard"} cards={state.discard} spacing={0} onClick={ ev => onClick(ev, "discard")} selected={selected}/>
        </CardRow>
        <CardRow>
          <Pile id={"pile1"} cards={state.pile1} onClick={ ev => onClick(ev, "pile1")} selected={selected}/>
          <Pile id={"pile2"} cards={state.pile2} onClick={ ev => onClick(ev, "pile2")} selected={selected}/>
          <Pile id={"pile3"} cards={state.pile3} onClick={ ev => onClick(ev, "pile3")} selected={selected}/>
          <Pile id={"pile4"} cards={state.pile4} onClick={ ev => onClick(ev, "pile4")} selected={selected}/>
          <Pile id={"pile5"} cards={state.pile5} onClick={ ev => onClick(ev, "pile5")} selected={selected}/>
          <Pile id={"pile6"} cards={state.pile6} onClick={ ev => onClick(ev, "pile6")} selected={selected}/>
          <Pile id={"pile7"} cards={state.pile7} onClick={ ev => onClick(ev, "pile7")} selected={selected}/>
        </CardRow>
        {notify ? (
            <ModalNotify
                id="notification"
                msg={"The game could end here... Would you like to end the game?"}
                onAccept={onEndGameAccept}
                onDecline={onNotifyDecline}
            />
        ) : null}`
        {error ? (
            <GameErrorMessage msg={error} hide={hideError}/>) : null}
      </GameBase>
    // </DndContext>
  );
};

Game.propTypes = {};