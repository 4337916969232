/* Copyright G. Hemingway, @2023 - All rights reserved */
"use strict";

import React from "react";
import styled from "styled-components";
import { css } from "styled-components";
import {FormButton, AnimatedGradientText} from "./shared.js";
import { useNavigate } from "react-router-dom";

const LandingBase = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr); // This creates a 12-column grid
  grid-gap: 10px; // This adds spacing between grid items
  //justify-content: 
  grid-area: main;
  & > * {
    grid-column: 3 / 10; // This spans the item across 4 columns
    }
`;

//https://styled-components.com/docs/basics#animations
const Label = styled.label`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`

const Input = styled.input.attrs({ type: "checkbox" })``;

const LabelText = styled.span`
  ${(props) => {
    switch (props.$mode) {
        case "dark":
            return css`
          background-color: black;
          color: white;
          ${Input}:checked + && {
            color: blue;
          }
        `;
        default:
            return css`
          color: black;
          ${Input}:checked + && {
            color: darkred;
          }
        `;
    }
}}
`;

const CheckListBase = styled.div`
    display: grid;
    justify-content: center;
`;

const IntroBase = styled.div`
    display: grid;
    justify-content: center;
    border: 1px solid black;
    padding: 10px;
`;

const CheckList = () => {
    let navigate = useNavigate();
    const [points, setPoints] = React.useState(0);
    const onCheck = (e, point) => {
        if (e.target.checked) {
            setPoints(points + point);
        } else {
            setPoints(points - point);
        }
    }

    return (
        <CheckListBase>
            <AnimatedGradientText>Check Lists: Total Points {points}</AnimatedGradientText>
            <Label>
                <Input onClick={(e) => onCheck(e, 30)}/>
                <LabelText>(30 points - mandatory) Instructor code review. Is it clean, well-structured, modular code.</LabelText>
            </Label>
            <Label>
                <Input onClick={(e) => onCheck(e, 20)}/>
                <LabelText>(20 points - mandatory) Deploy with HTTPS.</LabelText>
            </Label>
            <Label>
                <Input onClick={(e) => onCheck(e, 10)}/>
                <LabelText>(10 points) Enable modification of a user's profile</LabelText>
            </Label>
            <Label>
                <Input onClick={(e) => onCheck(e, 10)}/>
                <LabelText>(10 points) Fully working "results" page</LabelText>
            </Label>
            <Label>
                <Input onClick={(e) => onCheck(e, 30)}/>
                <LabelText>(30 points) Each move is click-able and renders the state of the game after the completion of the clicked move.</LabelText>
            </Label>
            <Label>
                <Input onClick={(e) => onCheck(e, 40)}/>
                <LabelText>(40 points) Register and log in via Github.</LabelText>
            </Label>
            <Label>
                <Input onClick={(e) => onCheck(e, 10)}/>
                <LabelText>(10 points) Recognize end of game</LabelText>
            </Label>
            <Label>
                <Input onClick={(e) => onCheck(e, 10)}/>
                <LabelText>(10 points) Infinite undo/redo</LabelText>
            </Label>
        </CheckListBase>
    )
}

export const Landing = ({loggedIn, username}) => {
    let navigate = useNavigate();
    const handleClick = (loggedIn, username) => {
        if (loggedIn) {
            navigate("/profile/" + username);
        } else {
            navigate("/login");
        }
    }

    return (
        <LandingBase>
            <CheckList />
            <FormButton onClick={()=> handleClick(loggedIn, username)}>Start Playing</FormButton>
            <IntroBase>
                <h2>About</h2>
                <p>This is a solitaire game that is built with React, Express, and MongoDB. <br/>
                    The game is deployed on AWS EC2 and uses Route53 to manage DNS.</p>
                <h2>How to Play</h2>
                <p>Click on the cards to move them. <br/>
                    You can move a card to the foundation if it is one rank higher and the same suit as the top card of the foundation. <br/>
                    You can move a card to the tableau if it is one rank lower and a different color than the top card of the tableau. <br/>
                    You can move a card to the waste if it is one rank higher and a different color than the top card of the waste.
                </p>
            </IntroBase>
        </LandingBase>
    );
}
