/* Copyright G. Hemingway, @2023 - All rights reserved */
"use strict";

import React from "react";
import PropTypes from "prop-types";
import styled, {keyframes} from "styled-components";
import {Link} from "react-router-dom";


const ErrorBase = styled.div`
  grid-column: 1 / 3;
  color: red;
  display: flex;
  justify-content: center;
  padding: 1em;
  min-height: 1.2em;
`;

export const ErrorMessage = ({ msg = "", hide = false }) => {
  return (
    <ErrorBase style={{ display: hide ? "none" : "inherit" }}>{msg}</ErrorBase>
  );
};

ErrorMessage.propTypes = {
  msg: PropTypes.string,
  hide: PropTypes.bool,
};

export const GameErrorMessage = ({ msg = "", hide = false }) => {
    return (
        <NotifyBase>
            <NotifyBox style={{ display: hide ? "none" : "inherit" }}>
                <ErrorBase style={{ display: hide ? "none" : "inherit" }}>{msg}</ErrorBase>
            </NotifyBox>
        </NotifyBase>
    );
};

const NotifyBase = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotifyBox = styled.div`
  padding: 2em;
  border: 1px solid #000;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
`;

export const ModalNotify = ({ msg = "", onAccept, onDecline, ...props }) => {
  return (
    <NotifyBase {...props}>
      <NotifyBox>
        <p>{msg}</p>
        {onAccept ? <FormButton onClick={onAccept}>Yes</FormButton> : null}
        {onDecline ? <FormButton onClick={onDecline}>No</FormButton> : null}
      </NotifyBox>
    </NotifyBase>
  );
};

export const FormBase = styled.form`
  display: grid;
  grid-template-columns: 30% 70%;
  grid-auto-rows: minmax(10px, auto);
  padding: 0.1em;
  @media (min-width: 500px) {
    padding: 1em;
  }
`;

export const ButtonsBase = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & > button {
        margin: 0.5em;
    }
`;

export const FormLabel = styled.label`
  padding: 0.5em 0.5em;
  text-align: right;
  font-weight: bold;
`;

export const FormInput = styled.input`
  margin: 0.5em 0;
  width: 75%;
  padding-left: 5px;
`;

export const FormButton = styled.button`
  margin: 0.5em 0;
  max-width: 200px;
  min-width: 150px;
  max-height: 2em;
  background: linear-gradient(180deg, goldenrod 0%, lightgoldenrodyellow 80%, #ffffff 100%);
  color: #122b40;
  border: 1px solid;
  border-radius: 5px;
  line-height: 2em;
  font-size: 0.8em;
  font-weight: bold;
  font-family: monospace, "Courier New";
  &:hover {
    background: #87cefa;
  }
`;

export const FormSelect = styled.select`
  font-size: 1em;
`;

export const FormHeader = styled.h2`
  grid-column: 1 / 3;
`;

export const InfoBlock = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  grid-template-areas: "labels info";
`;

export const InfoData = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & > p {
    margin: 0.5em 0.25em;
  }
`;

export const InfoLabels = styled(InfoData)`
  align-items: flex-end;
  font-weight: bold;
`;

export const ShortP = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

// Reference: https://javascript.plainenglish.io/animated-text-gradients-with-react-and-styled-components-93891797eaf1

export const gradient = keyframes`
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

export const AnimatedGradientText = styled.h1`
  animation: ${gradient} 5s ease-in-out infinite;
  background: linear-gradient(to right, #ee9ca7, #ffdde1, #2193b0, #6dd5ed);
  background-size: 300%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
`;

export const LinkBase = styled(Link)`
  animation: ${gradient} 5s ease-in-out infinite;
  background: linear-gradient(to right, #ef2543, #ff5a00, #ffc800, #4acfff, #1d8aff, #ef2543);
  background-size: 300%;
  background-clip: text;
  font-weight: bold;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  &:hover {
    border-bottom: 3px solid #ef2543;
  }
`;
