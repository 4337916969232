"use strict";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    ErrorMessage,
    FormBase,
    FormInput,
    FormLabel,
    FormButton,
    ModalNotify,
    ButtonsBase
} from "./shared.js";

export const Edit = (props) => {
    let navigate = useNavigate();
    let [state, setState] = useState({
        first_name: props.first_name,
        last_name: props.last_name,
        city: props.city,
        primary_email: props.primary_email,
    });
    let [error, setError] = useState("");
    let [notify, setNotify] = useState("");

    useEffect(() => {
        document.getElementById("first_name").focus();
    }, []);

    // Email validation function
    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const onChange = (ev) => {
        setError("");
        // Update from form and clear errors
        setState({
            ...state,
            [ev.target.name]: ev.target.value,
        });
        // Make sure the username is valid
        if (ev.target.name === "first_name") {
            if (ev.target.value === "") setError(`Error: ${ev.target.name} cannot be empty`);
        }
        // Make sure password is valid
        else if (ev.target.name === "last_name") {
            if (ev.target.value === "") setError(`Error: ${ev.target.name} cannot be empty`);
        }
        else if (ev.target.name === "city") {
            if (ev.target.value === "") setError(`Error: ${ev.target.name} cannot be empty`);
        }
        else if (ev.target.name === "primary_email") {
            if (!isValidEmail(ev.target.value)) setError(`Error: ${ev.target.name} malformed`);
        }
    };

    const onSubmit = async (ev) => {
        ev.preventDefault();
        setNotify(`Are you sure you want to update your profile?`);
    };

    const onEditCancel = (ev) => {
        ev.preventDefault();
        navigate(`/profile/${props.currentUser}`);
    }

    const onEditAccept = async () => {
        if (error !== "") return;
        const res = await fetch("/v1/user", {
            method: "PUT",
            body: JSON.stringify(state),
            credentials: "include",
            headers: {
                "content-type": "application/json",
            },
        });
        if (res.ok) {
            // Notify users
            setNotify(`Update Success!  You will now be redirect to profile page.`);
            navigate(`/profile/${props.currentUser}`);
        } else {
            const err = await res.json();
            setError(err.error);
        }

    }

    const onNotifyDecline = () => {
        setNotify("");
    }

    return (
        <div style={{ gridArea: "main" }}>
            {notify !== "" ? (
                <ModalNotify
                    id="notification"
                    msg={notify}
                    onAccept={onEditAccept}
                    onDecline={onNotifyDecline}
                />
            ) : null}
            <ErrorMessage msg={error} />
            <FormBase>
                <FormLabel htmlFor="first_name">First Name:</FormLabel>
                <FormInput
                    id="first_name"
                    name="first_name"
                    placeholder="First Name"
                    onChange={onChange}
                    value={state.first_name}
                />

                <FormLabel htmlFor="last_name">Last Name:</FormLabel>
                <FormInput
                    id="last_name"
                    name="last_name"
                    placeholder="Last Name"
                    onChange={onChange}
                    value={state.last_name}
                />

                <FormLabel htmlFor="city">City:</FormLabel>
                <FormInput
                    id="city"
                    name="city"
                    placeholder="City"
                    onChange={onChange}
                    value={state.city}
                />
                <FormLabel htmlFor="primary_email">Email:</FormLabel>
                <FormInput
                    id="primary_email"
                    name="primary_email"
                    placeholder="Email"
                    onChange={onChange}
                    value={state.primary_email}
                />
                <div />
                <ButtonsBase>
                    <FormButton id="submitBtn" onClick={onSubmit}>
                        Edit
                    </FormButton>
                    <FormButton onClick={onEditCancel}>
                        Cancel
                    </FormButton>
                </ButtonsBase>
            </FormBase>
        </div>
    );
}

Edit.prototype = {};